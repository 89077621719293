@use "reset.css";
@use "components/links.scss";

body {
  margin: 0;
  padding: 1rem;
  min-height: 100vh;
  font-family: 'Anek Telugu', sans-serif;

  background: rgb(228,168,17);
  background: linear-gradient(
                  50deg,
                  rgba(228,168,17,1) 28%,
                  rgba(255,220,100,1) 46%,
                  rgba(242,201,82,1) 65%,
                  rgba(255,214,95,1) 135%,
                  rgba(228,168,17,1) 172%
  );
  background-size: 200% 200%;
  background-attachment: fixed;

  color: white;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;

  display: flex;
  align-items: center;

  animation: gradient 8s ease-in-out infinite;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 50% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

section {
  max-width: 600px;
  margin: auto;
}

div.profile {
  margin: 1rem 0;
  text-align: center;

  h1 {
    margin: 1rem auto 0;
    font-weight: 500;
    font-size: 200%;
    text-shadow: 3px 3px 6px rgb(120 120 120 / 20%);
  }

  img {
    border-radius: 50%;
    border: 2px solid #fff;
    display: block;
    margin: auto;
  }

  .links a:before {
    background-color: white;
  }
}

p.large {
  font-size: 180%;
  line-height: 1.5;
  font-weight: 300;
  margin: 1.5rem 0;
  text-shadow: 3px 3px 6px rgb(120 120 120 / 20%);
}

div.container {
  margin: 2rem 0 0 0;
}

div.callout {
  background: rgba(255,255,255,0.3);
  color: #444;
  padding: 1rem;
  border-radius: 8px;

  h2 {
    margin: 1rem auto;
    font-weight: 500;
    font-size: 100%;
  }

  p {
    font-size: 120%;
    line-height: 1.5;
    font-weight: 300;
    margin: 1.5rem 0;
    text-shadow: 3px 3px 6px rgb(120 120 120 / 20%);
  }

  button,a.button {
    margin: 0;
    width: 100%;
    padding: 1rem;
    background: rgba(255,255,255,0.7);
    text-align: center;
    border-radius: 8px;
    cursor: pointer;
    display: block;
    text-decoration: none;

    &:hover {
      //background: rgba(255,255,255,1);
      box-shadow: 0 0 20px rgb(255 255 255 / 50%);
    }
  }
}

div.copyright {
  text-align: center;
  color: rgba(255,255,255,0.6);
  
  font-weight: 300;
}

//@media (min-width: 768px) {
//  body{
//    padding: 1rem 7rem;
//  }
//}
//
@media (min-width: 1024px) {
  section {
    //max-width: 800px;
  }
}
