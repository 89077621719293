:where(:not(html, iframe, canvas, img, svg, video, audio):not(svg *, symbol *)) {
  all: unset;
  display: revert;
}

*, :before, :after {
  box-sizing: border-box;
}

a, button {
  cursor: revert;
}

ol, ul, menu {
  list-style: none;
}

img {
  max-width: 100%;
}

table {
  border-collapse: collapse;
}

input, textarea {
  -webkit-user-select: auto;
}

textarea {
  white-space: revert;
}

meter {
  -webkit-appearance: revert;
  appearance: revert;
}

::placeholder {
  color: unset;
}

:where([hidden]) {
  display: none;
}

:where([contenteditable]:not([contenteditable="false"])) {
  -moz-user-modify: read-write;
  -webkit-user-modify: read-write;
  overflow-wrap: break-word;
  -webkit-line-break: after-white-space;
  -webkit-user-select: auto;
}

:where([draggable="true"]) {
  -webkit-user-drag: element;
}

a {
  text-decoration: underline;
}

a:hover {
  text-shadow: 0 0 20px #fff;
}

a.has-icon:before, button.has-icon:before {
  box-sizing: border-box;
  vertical-align: middle;
  content: "";
  width: 24px;
  height: 24px;
  background-color: #888;
  margin-bottom: 4px;
  margin-right: 4px;
  transition: background-color .3s ease-in;
  display: inline-block;
  -webkit-mask-position: 50%;
  mask-position: 50%;
  -webkit-mask-size: 96% 96%;
  mask-size: 96% 96%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
}

a.has-icon.web:before, button.has-icon.web:before {
  -webkit-mask-image: url("data:image/svg+xml,%3Csvg%20version%3D%221.1%22%20id%3D%22Capa_1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20x%3D%220%22%20y%3D%220%22%20viewBox%3D%220%200%20347.971%20347.971%22%20style%3D%22enable-background%3Anew%200%200%20347.971%20347.971%22%20xml%3Aspace%3D%22preserve%22%3E%3Cpath%20d%3D%22M340.114%20140.296%20173.994%200%207.857%20140.296v31.017l25.674-.043v176.701h101.878v-99.446h77.139v99.446h101.88V171.27l25.686.043z%22%2F%3E%3C%2Fsvg%3E");
  mask-image: url("data:image/svg+xml,%3Csvg%20version%3D%221.1%22%20id%3D%22Capa_1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20x%3D%220%22%20y%3D%220%22%20viewBox%3D%220%200%20347.971%20347.971%22%20style%3D%22enable-background%3Anew%200%200%20347.971%20347.971%22%20xml%3Aspace%3D%22preserve%22%3E%3Cpath%20d%3D%22M340.114%20140.296%20173.994%200%207.857%20140.296v31.017l25.674-.043v176.701h101.878v-99.446h77.139v99.446h101.88V171.27l25.686.043z%22%2F%3E%3C%2Fsvg%3E");
}

a.has-icon.github:before, button.has-icon.github:before {
  -webkit-mask-image: url("data:image/svg+xml,%3Csvg%20width%3D%2224%22%20height%3D%2224%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M12%20.297c-6.63%200-12%205.373-12%2012%200%205.303%203.438%209.8%208.205%2011.385.6.113.82-.258.82-.577%200-.285-.01-1.04-.015-2.04-3.338.724-4.042-1.61-4.042-1.61C4.422%2018.07%203.633%2017.7%203.633%2017.7c-1.087-.744.084-.729.084-.729%201.205.084%201.838%201.236%201.838%201.236%201.07%201.835%202.809%201.305%203.495.998.108-.776.417-1.305.76-1.605-2.665-.3-5.466-1.332-5.466-5.93%200-1.31.465-2.38%201.235-3.22-.135-.303-.54-1.523.105-3.176%200%200%201.005-.322%203.3%201.23.96-.267%201.98-.399%203-.405%201.02.006%202.04.138%203%20.405%202.28-1.552%203.285-1.23%203.285-1.23.645%201.653.24%202.873.12%203.176.765.84%201.23%201.91%201.23%203.22%200%204.61-2.805%205.625-5.475%205.92.42.36.81%201.096.81%202.22%200%201.606-.015%202.896-.015%203.286%200%20.315.21.69.825.57C20.565%2022.092%2024%2017.592%2024%2012.297c0-6.627-5.373-12-12-12%22%2F%3E%3C%2Fsvg%3E");
  mask-image: url("data:image/svg+xml,%3Csvg%20width%3D%2224%22%20height%3D%2224%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M12%20.297c-6.63%200-12%205.373-12%2012%200%205.303%203.438%209.8%208.205%2011.385.6.113.82-.258.82-.577%200-.285-.01-1.04-.015-2.04-3.338.724-4.042-1.61-4.042-1.61C4.422%2018.07%203.633%2017.7%203.633%2017.7c-1.087-.744.084-.729.084-.729%201.205.084%201.838%201.236%201.838%201.236%201.07%201.835%202.809%201.305%203.495.998.108-.776.417-1.305.76-1.605-2.665-.3-5.466-1.332-5.466-5.93%200-1.31.465-2.38%201.235-3.22-.135-.303-.54-1.523.105-3.176%200%200%201.005-.322%203.3%201.23.96-.267%201.98-.399%203-.405%201.02.006%202.04.138%203%20.405%202.28-1.552%203.285-1.23%203.285-1.23.645%201.653.24%202.873.12%203.176.765.84%201.23%201.91%201.23%203.22%200%204.61-2.805%205.625-5.475%205.92.42.36.81%201.096.81%202.22%200%201.606-.015%202.896-.015%203.286%200%20.315.21.69.825.57C20.565%2022.092%2024%2017.592%2024%2012.297c0-6.627-5.373-12-12-12%22%2F%3E%3C%2Fsvg%3E");
}

a.has-icon.linkedin:before, button.has-icon.linkedin:before {
  -webkit-mask-image: url("data:image/svg+xml,%3Csvg%20width%3D%2224%22%20height%3D%2224%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cg%20data-name%3D%22Layer%202%22%3E%3Cg%20data-name%3D%22linkedin%22%3E%3Cpath%20d%3D%22M15.15%208.4a5.83%205.83%200%200%200-5.85%205.82v5.88a.9.9%200%200%200%20.9.9h2.1a.9.9%200%200%200%20.9-.9v-5.88a1.94%201.94%200%200%201%202.15-1.93%202%202%200%200%201%201.75%202v5.81a.9.9%200%200%200%20.9.9h2.1a.9.9%200%200%200%20.9-.9v-5.88a5.83%205.83%200%200%200-5.85-5.82z%22%2F%3E%3Crect%20x%3D%223%22%20y%3D%229.3%22%20width%3D%224.5%22%20height%3D%2211.7%22%20rx%3D%22.9%22%20ry%3D%22.9%22%2F%3E%3Ccircle%20cx%3D%225.25%22%20cy%3D%225.25%22%20r%3D%222.25%22%2F%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E");
  mask-image: url("data:image/svg+xml,%3Csvg%20width%3D%2224%22%20height%3D%2224%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cg%20data-name%3D%22Layer%202%22%3E%3Cg%20data-name%3D%22linkedin%22%3E%3Cpath%20d%3D%22M15.15%208.4a5.83%205.83%200%200%200-5.85%205.82v5.88a.9.9%200%200%200%20.9.9h2.1a.9.9%200%200%200%20.9-.9v-5.88a1.94%201.94%200%200%201%202.15-1.93%202%202%200%200%201%201.75%202v5.81a.9.9%200%200%200%20.9.9h2.1a.9.9%200%200%200%20.9-.9v-5.88a5.83%205.83%200%200%200-5.85-5.82z%22%2F%3E%3Crect%20x%3D%223%22%20y%3D%229.3%22%20width%3D%224.5%22%20height%3D%2211.7%22%20rx%3D%22.9%22%20ry%3D%22.9%22%2F%3E%3Ccircle%20cx%3D%225.25%22%20cy%3D%225.25%22%20r%3D%222.25%22%2F%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E");
}

a.has-icon.print:before, button.has-icon.print:before {
  -webkit-mask-image: url("data:image/svg+xml,%3Csvg%20width%3D%2224%22%20height%3D%2224%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20aria-labelledby%3D%22printIconTitle%22%20stroke%3D%22%23000%22%20stroke-width%3D%222%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%20fill%3D%22none%22%20color%3D%22%23000%22%3E%3Cpath%20d%3D%22M17%2014v7H7v-7zM17%203v6H7V3z%22%2F%3E%3Cpath%20d%3D%22M7%2018H3V9h18v9h-4%22%2F%3E%3C%2Fsvg%3E");
  mask-image: url("data:image/svg+xml,%3Csvg%20width%3D%2224%22%20height%3D%2224%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20aria-labelledby%3D%22printIconTitle%22%20stroke%3D%22%23000%22%20stroke-width%3D%222%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%20fill%3D%22none%22%20color%3D%22%23000%22%3E%3Cpath%20d%3D%22M17%2014v7H7v-7zM17%203v6H7V3z%22%2F%3E%3Cpath%20d%3D%22M7%2018H3V9h18v9h-4%22%2F%3E%3C%2Fsvg%3E");
}

a.has-icon.download:before, button.has-icon.download:before {
  -webkit-mask-image: url("data:image/svg+xml,%3Csvg%20version%3D%221.1%22%20id%3D%22Layer_1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20x%3D%220%22%20y%3D%220%22%20viewBox%3D%220%200%20330%20330%22%20style%3D%22enable-background%3Anew%200%200%20330%20330%22%20xml%3Aspace%3D%22preserve%22%3E%3Cg%20id%3D%22XMLID_23_%22%3E%3Cpath%20id%3D%22XMLID_24_%22%20d%3D%22M154.389%20255.602c.351.351.719.683%201.103.998.169.138.347.258.52.388.218.164.432.333.659.484.212.142.432.265.649.395.202.121.4.248.608.359.223.12.453.221.681.328.215.102.427.21.648.301.223.092.45.167.676.247.236.085.468.175.709.248.226.068.456.119.684.176.246.062.489.131.739.181.263.052.529.083.794.121.219.031.435.073.658.095.492.048.986.075%201.48.075s.988-.026%201.479-.075c.226-.022.444-.064.667-.096.262-.037.524-.068.784-.12.255-.05.504-.121.754-.184.223-.057.448-.105.669-.172.246-.075.483-.167.724-.253.221-.08.444-.152.662-.242.225-.093.44-.202.659-.306.225-.106.452-.206.672-.324.21-.112.408-.239.611-.361.217-.13.437-.252.648-.394.222-.148.431-.314.644-.473.179-.134.362-.258.536-.4.365-.3.714-.617%201.049-.949.016-.016.034-.028.049-.044l70.002-69.998c5.858-5.858%205.858-15.355%200-21.213-5.857-5.857-15.355-5.858-21.213-.001l-44.396%2044.393V25c0-8.284-6.716-15-15-15-8.284%200-15%206.716-15%2015v183.785l-44.392-44.391c-5.857-5.858-15.355-5.858-21.213%200-5.858%205.858-5.858%2015.355%200%2021.213l69.997%2069.995z%22%2F%3E%3Cpath%20id%3D%22XMLID_25_%22%20d%3D%22M315%20160c-8.284%200-15%206.716-15%2015v115H30V175c0-8.284-6.716-15-15-15-8.284%200-15%206.716-15%2015v130c0%208.284%206.716%2015%2015%2015h300c8.284%200%2015-6.716%2015-15V175c0-8.284-6.716-15-15-15z%22%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E");
  mask-image: url("data:image/svg+xml,%3Csvg%20version%3D%221.1%22%20id%3D%22Layer_1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20x%3D%220%22%20y%3D%220%22%20viewBox%3D%220%200%20330%20330%22%20style%3D%22enable-background%3Anew%200%200%20330%20330%22%20xml%3Aspace%3D%22preserve%22%3E%3Cg%20id%3D%22XMLID_23_%22%3E%3Cpath%20id%3D%22XMLID_24_%22%20d%3D%22M154.389%20255.602c.351.351.719.683%201.103.998.169.138.347.258.52.388.218.164.432.333.659.484.212.142.432.265.649.395.202.121.4.248.608.359.223.12.453.221.681.328.215.102.427.21.648.301.223.092.45.167.676.247.236.085.468.175.709.248.226.068.456.119.684.176.246.062.489.131.739.181.263.052.529.083.794.121.219.031.435.073.658.095.492.048.986.075%201.48.075s.988-.026%201.479-.075c.226-.022.444-.064.667-.096.262-.037.524-.068.784-.12.255-.05.504-.121.754-.184.223-.057.448-.105.669-.172.246-.075.483-.167.724-.253.221-.08.444-.152.662-.242.225-.093.44-.202.659-.306.225-.106.452-.206.672-.324.21-.112.408-.239.611-.361.217-.13.437-.252.648-.394.222-.148.431-.314.644-.473.179-.134.362-.258.536-.4.365-.3.714-.617%201.049-.949.016-.016.034-.028.049-.044l70.002-69.998c5.858-5.858%205.858-15.355%200-21.213-5.857-5.857-15.355-5.858-21.213-.001l-44.396%2044.393V25c0-8.284-6.716-15-15-15-8.284%200-15%206.716-15%2015v183.785l-44.392-44.391c-5.857-5.858-15.355-5.858-21.213%200-5.858%205.858-5.858%2015.355%200%2021.213l69.997%2069.995z%22%2F%3E%3Cpath%20id%3D%22XMLID_25_%22%20d%3D%22M315%20160c-8.284%200-15%206.716-15%2015v115H30V175c0-8.284-6.716-15-15-15-8.284%200-15%206.716-15%2015v130c0%208.284%206.716%2015%2015%2015h300c8.284%200%2015-6.716%2015-15V175c0-8.284-6.716-15-15-15z%22%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E");
}

a.has-icon {
  margin: 0 .5rem;
}

body {
  min-height: 100vh;
  color: #fff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizelegibility;
  background: linear-gradient(50deg, #e4a811 28%, #ffdc64 46%, #f2c952 65%, #ffd65f 135%, #e4a811 172%) 0 0 / 200% 200% fixed;
  align-items: center;
  margin: 0;
  padding: 1rem;
  font-family: Anek Telugu, sans-serif;
  animation: gradient 8s ease-in-out infinite;
  display: flex;
}

@keyframes gradient {
  0% {
    background-position: 0%;
  }

  50% {
    background-position: 50%;
  }

  100% {
    background-position: 0%;
  }
}

section {
  max-width: 600px;
  margin: auto;
}

div.profile {
  text-align: center;
  margin: 1rem 0;
}

div.profile h1 {
  text-shadow: 3px 3px 6px #78787833;
  margin: 1rem auto 0;
  font-size: 200%;
  font-weight: 500;
}

div.profile img {
  border: 2px solid #fff;
  border-radius: 50%;
  margin: auto;
  display: block;
}

div.profile .links a:before {
  background-color: #fff;
}

p.large {
  text-shadow: 3px 3px 6px #78787833;
  margin: 1.5rem 0;
  font-size: 180%;
  font-weight: 300;
  line-height: 1.5;
}

div.container {
  margin: 2rem 0 0;
}

div.callout {
  color: #444;
  background: #ffffff4d;
  border-radius: 8px;
  padding: 1rem;
}

div.callout h2 {
  margin: 1rem auto;
  font-size: 100%;
  font-weight: 500;
}

div.callout p {
  text-shadow: 3px 3px 6px #78787833;
  margin: 1.5rem 0;
  font-size: 120%;
  font-weight: 300;
  line-height: 1.5;
}

div.callout button, div.callout a.button {
  width: 100%;
  text-align: center;
  cursor: pointer;
  background: #ffffffb3;
  border-radius: 8px;
  margin: 0;
  padding: 1rem;
  text-decoration: none;
  display: block;
}

div.callout button:hover, div.callout a.button:hover {
  box-shadow: 0 0 20px #ffffff80;
}

div.copyright {
  text-align: center;
  color: #fff9;
  font-weight: 300;
}

/*# sourceMappingURL=index.0200d38a.css.map */
