a {
  text-decoration: underline;
  &:hover {
    text-shadow: 0 0 20px rgb(255 255 255 / 100%);
  }
}

a.has-icon, button.has-icon {
  &:before {
    box-sizing: border-box;
    margin-right: 4px;
    vertical-align: middle;
    margin-bottom: 4px;
    display: inline-block;
    content: '';
    background-color: #888;
    width: 24px;
    height: 24px;
    //line-height: 24px;
    transition: .3s background-color ease-in;

    //mask-size: 24px 24px;
    mask-size: 96% 96%;
    mask-position: 50% 50%;
    mask-repeat: no-repeat;
  }
  &.web:before { mask-image: url('data-url:/img/website.svg') }
  &.github:before { mask-image: url('data-url:/img/github.svg') }
  &.linkedin:before { mask-image: url('data-url:/img/linkedin.svg') }
  &.print:before { mask-image: url('data-url:/img/print.svg') }
  &.download:before { mask-image: url('data-url:/img/download.svg') }
}

a.has-icon {
  margin: 0 .5rem;
}
